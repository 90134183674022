import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link, { LinkProps } from 'next/link';
import React, { ReactNode } from 'react';
import { colors, spacing } from '../../../utils/styleguide';
import Icon, { IconNames } from '../Icon';
import Typography, { TypographyVariants } from '../text/Typography';

interface GhostProps {
  underlineColor: string;
  hideUnderline?: boolean;
}

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => {
    return isPropValid(prop) || prop === 'underlineColor' || prop === 'tag' || prop === 'variant';
  },
})(
  (props: GhostProps) => css`
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    background-color: transparent;
    border: none;
    padding: 0;
    border-bottom: ${props.hideUnderline ? 0 : '2px'} solid ${props.underlineColor};
    cursor: pointer;
  `,
);

type Props = {
  onClick?: () => void;
  href?: LinkProps['href'];
  target?: string;
  rel?: string;
  label?: string;
  color?: string;
  underlineColor?: string;
  ariaLabel?: string;
  className?: string;
  hideUnderline?: boolean;
  leadingIconName?: IconNames;
  trailingIconName?: IconNames;
  textVariant?: TypographyVariants;
  children?: ReactNode;
};

const GhostButton = ({
  onClick,
  href = '',
  target,
  rel,
  label,
  color = colors.darkGrey,
  underlineColor = colors.darkGrey,
  ariaLabel,
  className,
  hideUnderline,
  leadingIconName,
  trailingIconName,
  textVariant = 'buttonSmall',
  children,
}: Props) => {
  const handleOnClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (onClick) {
      onClick();
    }
  };
  return (
    <Link href={href} passHref={!!href} legacyBehavior>
      <StyledTypography
        aria-label={ariaLabel}
        useBuiltInMargins
        className={className}
        color={color}
        variant={textVariant}
        tag={href ? 'a' : 'button'}
        target={target}
        rel={rel}
        onClick={onClick ? handleOnClick : () => null}
        underlineColor={underlineColor}
        hideUnderline={hideUnderline}
      >
        {leadingIconName && (
          <Icon
            name={leadingIconName}
            size={16}
            stroke={color}
            css={css`
              margin-right: ${spacing[1]}px;
            `}
          />
        )}
        {label || children}
        {trailingIconName && (
          <Icon
            onClick={onClick ? handleOnClick : () => null}
            size={16}
            name={trailingIconName}
            stroke={color}
            css={css`
              margin-left: ${spacing[1]}px;
            `}
          />
        )}
      </StyledTypography>
    </Link>
  );
};

export default GhostButton;
